import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSearchQuery } from "../../../store/actions/index";

export default function SearchBox({ className,type }) {

  const searchQuery = useSelector((state) => state.searchQuery);
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const handleSearch = (e) => {
    dispatch(setSearchQuery(text));
    navigate("/search-products")
  };




  const [text, setText] = useState("")

  const handleTextChange = (event) => {
    setText(event.target.value)
  }

  const handleEnterKey = (event) => {
    if (event.key === "Enter") {
      // Your custom logic for Enter key press here
      handleSearch()
    }
  };

  return (
    <>
      <div
        className={`w-full h-full flex items-center  border border-qgray-border bg-white ${
          className || ""
        }`}
      >
        <div className="flex-1 bg-red-500 h-full">
          <form action="#" className="h-full">
            <input
              type="text"
              className="search-input"
              placeholder="Search Product..."
              onChange={handleTextChange}
              onKeyDown={handleEnterKey}
              value={text}
              name="search"
            />
          </form>
        </div>
        {/* <div className="w-[1px] h-[22px] bg-qgray-border"></div> */}
        {/* <div className="flex-1 flex items-center px-4">
        </div> */}
        <button
          className={` w-[93px] h-full text-sm font-600  ${type===3?'bg-qh3-blue text-white':'search-btn'}`}
          type="button" onClick={handleSearch}
        >
          Search
        </button>
      </div>
    </>
  );
}
