import { useState } from "react";
import DiscountBanner from "../HomeTwo/DiscountBanner";
import Drawer from "../Mobile/Drawer";
import Footer from "./Footers/FooterTwo";
import HeaderTwo from "./Headers/HeaderTwo";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { useDispatch, useSelector } from "react-redux";
import { hideToast } from "../../store/actions";
import AlertToast from "../AlertToast/AlertToast";

export default function LayoutHomeTwo({ children, childrenClasses }) {
	const [drawer, setDrawer] = useState(false);
	const app = useSelector((state) => state.app);

	const dispatch = useDispatch();

	return (
		<>
			<Drawer open={drawer} action={() => setDrawer(!drawer)} />
			<div className="w-full overflow-x-hidden relative">
				{app.toast.show && (
					<AlertToast
						message={app.toast.message}
						type={app.toast.type}
						duration={app.toast.duration}
						onClose={() => dispatch(hideToast())}
					/>
				)}
				<HeaderTwo drawerAction={() => setDrawer(!drawer)} />
				<div className={`w-full  ${childrenClasses || "pt-[0px] pb-[0px]"}`}>
					{children && children}
				</div>
				<Footer />
				{app.loading && <LoadingSpinner />}
			</div>
		</>
	);
}
