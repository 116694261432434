import { useEffect, useState } from "react";
import "react-input-range/lib/css/index.css";

import BreadcrumbCom from "../BreadcrumbCom";
import ProductCardStyleOne from "../Helpers/Cards/ProductCardStyleOne";
import DataIteration from "../Helpers/DataIteration";
import LayoutHomeTwo from "../Partials/LayoutHomeTwo";
import CategoryFilter from "./CategoryFilter";
import { useDispatch, useSelector } from "react-redux";
import { showLoader, hideLoader } from "../../store/actions";

import { useParams, useNavigate } from "react-router-dom";

import axios from "axios";
import "./Category.css";
import { BASE_URL } from "../../utils/response";

export default function Category() {
	const dispatch = useDispatch();

	const checkboxHandler = (e) => {
		const { name } = e.target;
		setFilter((prevState) => ({
			...prevState,
			[name]: !prevState[name],
		}));
	};

	const [filterToggle, setToggle] = useState(false);

	const [products, setProducts] = useState([]);
	const [page, setPage] = useState(1);
	const [total, setTotal] = useState(0);
	const [subcategories, setSubcategories] = useState([]);

	const { categoryslug } = useParams();

	const [category, setCategory] = useState({});

	const [filterForm, setFilterForm] = useState({
		subCategorySelected: "",
		sort: "price-DESC",
	});

	const [searchText, setSearchText] = useState("");

	useEffect(() => {
		dispatch(showLoader());
		axios
			.get(`${BASE_URL}/categories-listing-api/${categoryslug}`)
			.then((response) => {
				setCategory(response.data?.data);

				let subcategoryArray = response.data?.data?.childs?.map(
					(category, index) => {
						return {
							slug: category.slug,
							title: category.slug,
						};
					}
				);

				setSubcategories(subcategoryArray);
				setFilterForm({
					subCategorySelected: "",
					sort: "price-DESC",
				});
				setSearchText("");
				dispatch(hideLoader());
			});
	}, [categoryslug]);

	useEffect(() => {
		applyFilter();
	}, [category]);

	useEffect(() => {
		applyFilter();
	}, [filterForm]);

	const applyFilter = () => {
		let url = `${BASE_URL}/products-listing-api?per_page=9&page_no=${page}`;

		if (filterForm.subCategorySelected === "") {
			url =
				url + ("&category=" + encodeURI(category.label).replace("&", "%26"));
		} else {
			url = url + ("&category=" + encodeURI(filterForm.subCategorySelected));
		}

		url =
			url +
			("&order_by=" +
				filterForm.sort.split("-")[0] +
				"&order_as=" +
				filterForm.sort.split("-")[1]);

		url = searchText ? url + ("&search=" + searchText) : url;

		axios.get(url).then((response) => {
			let productArray = response.data?.data?.records?.map((product, index) => {
				return {
					id: product.id,
					image: product.ImgURL + product.thumbnail,
					review: product.review ? product.review : 4,
					title: product.label,
					offer_price: product.price ? product.price : 22,
					price: product.variants.filter((x) => x.is_available == 1)[0]
						? product.variants.filter((x) => x.is_available == 1)[0]?.price
						: 0,
					campaingn_product: false,
					product_type: null,
					slug: product.slug,
				};
			});
			setTotal(response.data?.data?.pagination?.total);
			setPage(response.data?.data?.pagination?.current_page);
			setProducts(productArray);
		});
	};

	const subCategoryHandler = (selectedOption) => {
		setFilterForm({
			...filterForm,
			subCategorySelected: selectedOption,
		});
	};

	const sortHandler = (value) => {
		setFilterForm({
			...filterForm,
			sort: value,
		});
	};

	const searchTextHandler = (value) => {
		setSearchText(value);
	};

	const onSearch = () => {
		applyFilter();
	};

	return (
		<>
			<LayoutHomeTwo>
				<div className="products-page-wrapper w-full">
					<div className="container-x mx-auto">
						<div className="w-full lg:flex lg:space-x-[30px]">
							<div className="lg:w-[270px]">
								<CategoryFilter
									className="mb-[30px]"
									subCategoryHandler={subCategoryHandler}
									subcategories={subcategories}
									subCategorySelected={filterForm.subCategorySelected}
									sortSelected={filterForm.sort}
									sortHandler={sortHandler}
									searchText={searchText}
									searchTextHandler={searchTextHandler}
									onSearch={onSearch}
								/>
								{/* ads */}
								<div className="w-full hidden lg:block h-[295px]">
									<img
										src={`${process.env.PUBLIC_URL}/assets/images/ads-5.png`}
										alt=""
										className="w-full h-full object-contain"
									/>
								</div>
							</div>

							<div className="flex-1">
								<div className="products-sorting w-full bg-white md:h-[70px] flex md:flex-row flex-col md:space-y-0 space-y-5 md:justify-between md:items-center p-[30px] mb-[40px]">
									<div>
										<p className="font-400 text-[13px]">
											<span className="text-qgray"> Showing</span>{" "}
											{page * 9 - 8}–{page * 9} of {total} results
										</p>
									</div>
									<div className="flex space-x-3 items-center">
										<span className="font-400 text-[13px]">Sort by:</span>
										<div className="flex space-x-3 items-center">
											<select
												value={filterForm.sort}
												onChange={(event) => {
													sortHandler(event.target.value);
												}}
											>
												<option value="title-ASC">Name: A - Z</option>
												<option value="title-DESC">Name: Z - A</option>
												<option value="price-DESC">Price: High to Low</option>
												<option value="price-ASC">Price: Low to High</option>
											</select>
										</div>
									</div>
									<button
										onClick={() => setToggle(!filterToggle)}
										type="button"
										className="w-10 lg:hidden h-10 rounded flex justify-center items-center border border-qyellow text-qyellow"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											className="h-6 w-6"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
											strokeWidth="2"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
											/>
										</svg>
									</button>
								</div>
								<div className="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1  xl:gap-[30px] gap-5 mb-[40px]">
									<DataIteration datas={products} startLength={0} endLength={6}>
										{({ datas }) => (
											<div data-aos="fade-up" key={datas.id}>
												<div data-aos="fade-up" key={datas.id}>
													<ProductCardStyleOne datas={datas} />
												</div>
											</div>
										)}
									</DataIteration>
								</div>

								<div className="w-full h-[164px] overflow-hidden mb-[40px]">
									<img
										src={`${process.env.PUBLIC_URL}/assets/images/ads-6.png`}
										alt=""
										className="w-full h-full object-contain"
									/>
								</div>
								<div className="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 xl:gap-[30px] gap-5 mb-[40px]">
									<DataIteration
										datas={products}
										startLength={6}
										endLength={15}
									>
										{({ datas }) => (
											<div data-aos="fade-up" key={datas.id}>
												<ProductCardStyleOne datas={datas} />
											</div>
										)}
									</DataIteration>
								</div>
								<div className="products-sorting w-full bg-white md:h-[70px] flex md:flex-row flex-col md:space-y-0 space-y-5 md:justify-between md:items-center p-[30px]">
									<div>
										<p className="font-400 text-[13px]">
											<span className="text-qgray"> Showing</span>{" "}
											{page * 9 - 8}–{page * 9} of {total} results
										</p>
									</div>
									<div className="flex space-x-3 items-center">
										<span className="font-400 text-[13px]">Sort by:</span>
										<div className="flex space-x-3 items-center">
											<select
												value={filterForm.sort}
												onChange={(event) => {
													sortHandler(event.target.value);
												}}
											>
												<option value="title-ASC">Name: A - Z</option>
												<option value="title-DESC">Name: Z - A</option>
												<option value="price-DESC">Price: High to Low</option>
												<option value="price-ASC">Price: Low to High</option>
											</select>
										</div>
									</div>
									<button
										onClick={() => setToggle(!filterToggle)}
										type="button"
										className="w-10 lg:hidden h-10 rounded flex justify-center items-center border border-qyellow text-qyellow"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											className="h-6 w-6"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
											strokeWidth="2"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
											/>
										</svg>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</LayoutHomeTwo>
		</>
	);
}
