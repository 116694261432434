import React, { useState } from "react";
import TrackingOrderBox from "./TrackingOrderBox";
import axios from "axios";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../utils/response";

const TrackingButtonCmp = (data) => {
	const [isVisible, setIsVisible] = useState(false);
	const shopUserData = useSelector((state) => state.shopUserData.shopUserData);
	const [trackingData, setTrackingData] = useState([]);
	const fetchData = async () => {
		try {
			const res = await axios.post(
				`${BASE_URL}/ups-tracking`,
				{ tracking_id: data.trackingId },
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${shopUserData.token}`,
					},
				}
			);

			setTrackingData(res?.data?.data?.trackResponse.shipment[0].package);

			console.log(res?.data?.data?.trackResponse.shipment[0].package);
		} catch (error) {
			console.error("There was a problem with the fetch operation:", error);
		}
	};

	// Function to toggle visibility
	const toggleVisibility = () => {
		if (trackingData.length == 0) fetchData();
	};

	return (
		<div className="border-b w-full">
			<button
				onClick={toggleVisibility}
				className="mt-5 mb-5 flex justify-between items-center px-5 h-10 bg-[#F6F6F6] hover:bg-qh2-green transition-all duration-300 ease-in-out cursor-pointer text-qblack hover:text-white"
			>
				Track Order
			</button>
			<TrackingOrderBox trackingData={trackingData} />
		</div>
	);
};

export default TrackingButtonCmp;
