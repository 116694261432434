const initialState = {
    searchQuery: "",
    data: [], // Store the data you want to display
  };
  
  export const searchReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_SEARCH_QUERY":
        return {
          ...state,
          searchQuery: action.payload,
        };
      // Add other cases for updating the data
      default:
        return state;
    }
  };

  export default searchReducer;