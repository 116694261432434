import { Route, Routes } from "react-router-dom";
import About from "./components/About";
import AllProductPage from "./components/AllProductPage";
import Category from "./components/Category";
import Login from "./components/Auth/Login/index";
import Profile from "./components/Auth/Profile";
import Signup from "./components/Auth/Signup";
import BecomeSaller from "./components/BecomeSaller";
import Blogs from "./components/Blogs";
import Blog from "./components/Blogs/Blog.jsx";
import CardPage from "./components/CartPage";
import CheakoutPage from "./components/CheakoutPage";
import Contact from "./components/Contact";
import Faq from "./components/Faq";
import FlashSale from "./components/FlashSale";
import FourZeroFour from "./components/FourZeroFour";
import Home from "./components/Home";
import HomeTwo from "./components/HomeTwo";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ProductsCompaire from "./components/ProductsCompaire/index";
import SallerPage from "./components/SallerPage";
import Sallers from "./components/Sellers";
import SingleProductPage from "./components/SingleProductPage";
import TermsCondition from "./components/TermsCondition/index";
import TrackingOrder from "./components/TrackingOrder";
import Wishlist from "./components/Wishlist";
import HomeThree from "./components/HomeThree";
import HomeFour from "./components/HomeFour";
import HomeFive from "./components/HomeFive";
import PurchaseThankyou from "./components/PurchaseThankyou";
import TrackingOrderDetail from "./components/TrackingOrderDetail/index.jsx";
import ProductReview from "./components/ProductReview/ProductReview.jsx";
import ReviewDialog from "./components/ReviewDialog/ReviewDialog.jsx";

export default function Routers() {
	return (
		<Routes>
			<Route exact path="/" element={<Home />} />
			<Route exact path="/login" element={<Login />} />
			{/* <Route exact path="/home-two" element={<HomeTwo />} />
      <Route exact path="/home-three" element={<HomeThree />} />
      <Route exact path="/home-four" element={<HomeFour />} />
      <Route exact path="/home-five" element={<HomeFive />} /> */}

			<Route
				exact
				path="/review/:orderno/:productslug"
				element={<ReviewDialog />}
			/>
			<Route exact path="/search-products" element={<AllProductPage />} />
			<Route exact path="/category/:categoryslug" element={<Category />} />
			<Route
				exact
				path="/product/:productslug"
				element={<SingleProductPage />}
			/>
			<Route exact path="/cart" element={<CardPage />} />
			<Route exact path="/checkout" element={<CheakoutPage />} />
			<Route exact path="/wishlist" element={<Wishlist />} />
			<Route exact path="/thankyou" element={<PurchaseThankyou />} />

			{/* <Route exact path="/flash-sale" element={<FlashSale />} />
      <Route exact path="/saller-page" element={<SallerPage />} /> */}
			{/* <Route exact path="/products-compaire" element={<ProductsCompaire />} /> */}
			{/* <Route exact path="/sallers" element={<Sallers />} />
      <Route exact path="/about" element={<About />} />
      <Route exact path="/blogs" element={<Blogs />} />
      <Route exact path="/blogs/blog" element={<Blog />} />*/}
			<Route exact path="/orders" element={<TrackingOrder />} />

			<Route
				exact
				path="/order/:ordernumber"
				element={<TrackingOrderDetail />}
			/>

			{/* <Route exact path="/contact" element={<Contact />} />
      <Route exact path="/faq" element={<Faq />} />
      <Route exact path="/login" element={<Login />} />
    <Route exact path="/signup" element={<Signup />} />*/}
			<Route exact path="/profile" element={<Profile />} />
			{/*<Route exact path="/become-saller" element={<BecomeSaller />} />
      <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route exact path="/terms-condition" element={<TermsCondition />} /> */}
			<Route exact path="*" element={<FourZeroFour />} />
		</Routes>
	);
}
