// LoadingSpinner.js
import React from "react";

const LoadingSpinner = () => {
	return (
		<div className="fixed top-0" style={{ zIndex: "9999" }}>
			<div className="bg-white flex items-center justify-center h-screen w-screen">
				<div className="border-t-4 border-b-4 border-blue-900 border-solid rounded-full animate-spin h-12 w-12"></div>
				<span className="ml-3 text-xl font-semibold text-blue-900"></span>
			</div>
		</div>
	);
};

export default LoadingSpinner;
