import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import BreadcrumbCom from "../../BreadcrumbCom";
import Layout from "../../Partials/LayoutHomeTwo";
import IcoAdress from "./icons/IcoAdress";
import IcoCart from "./icons/IcoCart";
import IcoDashboard from "./icons/IcoDashboard";
import IcoLogout from "./icons/IcoLogout";
import IcoLove from "./icons/IcoLove";
import IcoPassword from "./icons/IcoPassword";
import IcoPayment from "./icons/IcoPayment";
import IcoPeople from "./icons/IcoPeople";
import IcoReviewHand from "./icons/IcoReviewHand";
import IcoSupport from "./icons/IcoSupport";
import AddressesTab from "./tabs/AddressesTab";
import Dashboard from "./tabs/Dashboard";
import OrderTab from "./tabs/OrderTab";
import PasswordTab from "./tabs/PasswordTab";
import Payment from "./tabs/Payment";
import ProfileTab from "./tabs/ProfileTab";
import ReviewTab from "./tabs/ReviewTab";
import SupportTab from "./tabs/SupportTab";
import WishlistTab from "./tabs/WishlistTab";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "../../../utils/response";

export default function Profile() {
	const datas = [];
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const shopUserData = useSelector((state) => state.shopUserData.shopUserData);

	const [switchDashboard, setSwitchDashboard] = useState(false);
	const location = useLocation();
	const getHashContent = location.hash.split("#");
	const [active, setActive] = useState("dashboard");
	useEffect(() => {
		setActive(
			getHashContent && getHashContent.length > 1
				? getHashContent[1]
				: "dashboard"
		);
	}, [getHashContent]);

	useEffect(() => {
		// axios
		// 	.post(`${BASE_URL}/login`, body)
		// 	.then((response) => {
		//
		// 		dispatch(login(response.data.data));
		// 		onClose();
		// 		if (checkout) {
		// 			navigate("/checkout");
		// 		} else {
		// 			location.reload();
		// 			window.scrollTo(0, 0);
		// 		}
		// 	});
	});

	const logout = () => {
		const headers = {
			"Content-Type": "application/json",
			Authorization: "Bearer " + shopUserData.token,
		};
		axios
			.post(
				`${BASE_URL}/logout`,
				{},
				{
					headers: headers,
				}
			)
			.then((response) => {
				if (response?.data?.success) {
					clearSession();
					navigate("/");
				} else {
					alert(response?.data?.message);
				}
			})
			.catch((e) => {
				alert(e.message + ". " + e.response?.data?.message);
			});
	};

	const clearSession = () => {
		dispatch({ type: "EMPTY_CART" });
		dispatch({ type: "EMPTY_WISHLIST" });
		dispatch({ type: "LOGOUT" });
	};

	return (
		<Layout childrenClasses="pt-0 pb-0">
			<div className="profile-page-wrapper w-full">
				<div className="container-x mx-auto">
					<div className="w-full my-10">
						<BreadcrumbCom
							paths={[
								{ name: "home", path: "/" },
								{ name: "profile", path: "/profile" },
							]}
						/>
						<div className="w-full bg-white px-10 py-9">
							<div className="title-area w-full flex justify-between items-center">
								<h1 className="text-[22px] font-bold text-qblack">
									Your Profile
								</h1>
								{/* <div className="switch-dashboard flex space-x-3 items-center">
                  <p className="text-qgray text-base">Switch Dashboard</p>
                  <button
                    onClick={() => setSwitchDashboard(!switchDashboard)}
                    type="button"
                    className="w-[73px] h-[31px] border border-[#D9D9D9] rounded-full relative "
                  >
                    <div
                      className={`w-[23px] h-[23px] bg-qblack rounded-full absolute top-[3px] transition-all duration-300 ease-in-out ${
                        switchDashboard ? "left-[44px]" : "left-[4px]"
                      }`}
                    ></div>
                  </button>
                </div> */}
							</div>
							<div className="profile-wrapper w-full mt-8 flex space-x-10">
								<div className="w-[236px] min-h-[600px] border-r border-[rgba(0, 0, 0, 0.1)]">
									<div className="flex flex-col space-y-10">
										<div className="item group">
											<Link to="/profile#profile">
												<div className="flex space-x-3 items-center text-qgray hover:text-qblack">
													<span>
														<IcoPeople />
													</span>
													<span className=" font-normal text-base">
														Personal
													</span>
												</div>
											</Link>
										</div>
										<div className="item group">
											<Link to="/profile#address">
												<div className="flex space-x-3 items-center text-qgray hover:text-qblack">
													<span>
														<IcoAdress />
													</span>
													<span className=" font-normal text-base">
														Address
													</span>
												</div>
											</Link>
										</div>

										{/* <div className="item group">
											<Link to="/profile#wishlist">
												<div className="flex space-x-3 items-center text-qgray hover:text-qblack">
													<span>
														<IcoLove />
													</span>
													<span className=" font-normal text-base">
														Wishlist
													</span>
												</div>
											</Link>
										</div> */}
										<div className="item group">
											<Link to="/profile#order">
												<div className="flex space-x-3 items-center text-qgray hover:text-qblack">
													<span>
														<IcoCart />
													</span>
													<span className=" font-normal text-base">Orders</span>
												</div>
											</Link>
										</div>
										<div className="item group">
											<Link to="/profile#review">
												<div className="flex space-x-3 items-center text-qgray hover:text-qblack">
													<span>
														<IcoReviewHand />
													</span>
													<span className=" font-normal text-base">
														Reviews
													</span>
												</div>
											</Link>
										</div>

										{/* <div className="item group">
											<Link to="/profile#payment">
												<div className="flex space-x-3 items-center text-qgray hover:text-qblack">
													<span>
														<IcoPayment />
													</span>
													<span className=" font-normal text-base">
														Payment Method
													</span>
												</div>
											</Link>
										</div> */}
										<div className="item group">
											<Link to="/profile#password">
												<div className="flex space-x-3 items-center text-qgray hover:text-qblack">
													<span>
														<IcoPassword />
													</span>
													<span className=" font-normal text-base">
														Security & Password
													</span>
												</div>
											</Link>
										</div>
										<div className="item group">
											<div
												className="flex space-x-3 items-center text-qgray hover:text-qblack cursor-pointer"
												onClick={logout}
											>
												<span>
													<IcoLogout />
												</span>
												<span className=" font-normal text-base">Logout</span>
											</div>
										</div>
									</div>
								</div>
								<div className="flex-1">
									<div className="item-body dashboard-wrapper w-full">
										{active === "dashboard" ? (
											<ProfileTab />
										) : active === "profile" ? (
											<>
												<ProfileTab />
											</>
										) : active === "payment" ? (
											<>
												<Payment />
											</>
										) : active === "order" ? (
											<>
												<OrderTab />
											</>
										) : active === "wishlist" ? (
											<>
												<WishlistTab />
											</>
										) : active === "address" ? (
											<>
												<AddressesTab />
											</>
										) : active === "password" ? (
											<>
												<PasswordTab />
											</>
										) : active === "support" ? (
											<>
												<SupportTab />
											</>
										) : active === "review" ? (
											<>
												<ReviewTab products={datas.products} />
											</>
										) : (
											""
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
}
