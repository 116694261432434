import React, { useState, useEffect } from "react";

const AlertToast = ({ message, type, duration, onClose }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      onClose();
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onClose]);

  const getTypeClasses = () => {
    switch (type) {
      case "success":
        return "alert-success-500 text-white";
      case "error":
        return "alert-error-500 text-white";
      case "warning":
        return "alert-warning-500 text-black";
      default:
        return "alert-info-500  text-white";
    }
  };

  return visible ? (
    <div
      className={`fixed top-4 rounded left-1/2 transform -translate-x-1/2 w-auto px-16 py-4 text-center transition-transform ${
        visible ? "translate-y-0" : "-translate-y-full"
      } ${getTypeClasses()} ease-in-out duration-300 z-50`}
    >
      {message}
    </div>
  ) : null;
};

export default AlertToast;
