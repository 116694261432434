import { combineReducers } from 'redux';
import cartReducer from './CartReducer';
import wishlistReducer from './WishlistReducer';
import profileReducer from './ProfileReducer';
import searchReducer from './SearchReducer';
import appReducer from './AppReducer';

const rootReducer = combineReducers({
  cart: cartReducer,
  wishlist: wishlistReducer,
  shopUserData: profileReducer,
  search: searchReducer,
  app: appReducer,
});

export default rootReducer;