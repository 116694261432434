// import { useEffect, useState } from "react";

import SectionStyleFour from "../Helpers/SectionStyleFour";
import SectionStyleOne from "../Helpers/SectionStyleOne";
import SectionStyleThree from "../Helpers/SectionStyleThree";
import SectionStyleTwo from "../Helpers/SectionStyleTwo";
import ViewMoreTitle from "../Helpers/ViewMoreTitle";
import Layout from "../Partials/Layout";
// import Ads from "./Ads";
import Banner from "../HomeThree/Banner";
import BestSellers from "./BestSellers";
import BrandSection from "./BrandSection";
import CampaignCountDown from "./CampaignCountDown";
import ProductsAds from "./ProductsAds";
import LayoutHomeTwo from "../Partials/LayoutHomeTwo";
import { useDispatch, useSelector } from "react-redux";
import { showLoader, hideLoader } from "../../store/actions";

import { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../utils/response";

export default function Home() {
	const dispatch = useDispatch();

	const [newArrivals, setNewArrivals] = useState([]);
	const [topProducts, setTopProducts] = useState([]);
	const [popularSales, setPopularSales] = useState([]);

	dispatch(hideLoader());

	useEffect(() => {
		dispatch(showLoader());
		axios.get(`${BASE_URL}/home`).then((response) => {
			let new_arrivals = response.data.data.new_arrivals.map((item) => {
				return {
					product_slug: item.slug,
					slug: item.slug,
					id: item.slug,
					image: item.ImgURL + item.thumbnail,
					title: item.label,
					price: item.price,
					review: item.rating ? item.rating : 5,
				};
			});
			setNewArrivals(new_arrivals);
			let top_products = response.data.data.top_products.map((item) => {
				return {
					product_slug: item.slug,
					slug: item.slug,
					id: item.slug,
					image: item.ImgURL + item.thumbnail,
					title: item.label,
					price: item.price,
					review: item.rating ? item.rating : 5,
				};
			});
			setTopProducts(top_products);
			let popular_sales = response.data.data.popular_sales.map((item) => {
				return {
					product_slug: item.slug,
					slug: item.slug,
					id: item.slug,
					image: item.ImgURL + item.thumbnail,
					title: item.label,
					price: item.price,
					review: item.rating ? item.rating : 5,
				};
			});
			setPopularSales(popular_sales);
			//dispatch(hideLoader());
		});
	}, []);

	return (
		<>
			<LayoutHomeTwo>
				{/* {ads && <Ads handler={adsHandle} />} */}

				<Banner className="banner-wrapper mb-[60px]" />

				<SectionStyleOne
					products={topProducts.slice(2, topProducts.length)}
					categoryTitle=""
					sectionTitle="Top Selling Products"
					seeMoreUrl="/all-products"
					className="category-products mb-[60px]"
				/>

				<ViewMoreTitle
					className="top-selling-product mb-[60px]"
					seeMoreUrl="/all-products"
					categoryTitle="Popular Sales"
				>
					<SectionStyleTwo products={popularSales.slice(0, 4)} />
				</ViewMoreTitle>

				<ViewMoreTitle
					className="top-selling-product mb-[60px]"
					seeMoreUrl="/all-products"
					categoryTitle="New Arrivals"
				>
					<SectionStyleTwo products={newArrivals.slice(0, 4)} />
				</ViewMoreTitle>

				<BrandSection
					sectionTitle="Shop by Brand"
					className="brand-section-wrapper mb-[60px]"
				/>
			</LayoutHomeTwo>
		</>
	);
}
