import axios from "axios";

// export const BASE_URL = "https://buildlink.designfinix.com/api";
// export const UPLOADS_URL = "https://buildlink.designfinix.com/uploads/projects/";
// export const IMG_URL = "https://buildlink.designfinix.com/uploads/portfolios";
// export const Symbol_URL = "https://buildlink.designfinix.com/uploads/symbols/";

export const BASE_URL = "https://bladmin.buildlink.com/api";
export const BASE_OPEN_URL = "https://bladmin.buildlink.com";
export const UPLOADS_URL = "https://bladmin.buildlink.com/uploads/projects/";
export const IMG_URL = "https://bladmin.buildlink.com/uploads/portfolios";
export const Symbol_URL = "https://bladmin.buildlink.com/uploads/symbols/";

// export const BASE_URL = "https://bladmin.springredux.com/api";
// export const BASE_OPEN_URL = "https://bladmin.springredux.com";
// export const UPLOADS_URL = "https://bladmin.springredux.com/uploads/projects/";
// export const IMG_URL = "https://bladmin.springredux.com/uploads/portfolios";
// export const Symbol_URL = "https://bladmin.springredux.com/uploads/symbols/";

const getHeader = () => {
	return {};
};

export const getMethodCustomHeader = (relativesUrl) => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${BASE_OPEN_URL}/${relativesUrl}`, { headers: getHeader() })
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
