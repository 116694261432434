const initialState = {
	items: [],
};

const cartReducer = (state = initialState, action) => {
	switch (action.type) {
		case "ADD_TO_CART":
			const item = action.payload;
			const existingItemIndex = state?.items.findIndex(
				(cartItem) =>
					cartItem.slug === item.slug &&
					cartItem.variant.variant === item.variant.variant
			);

			if (existingItemIndex !== -1) {
				// If the item already exists in the cart, increase its quantity
				const updatedCartItems = [...state.items];
				updatedCartItems[existingItemIndex].quantity += 1;

				return {
					...state,
					items: updatedCartItems,
				};
			} else {
				// If the item is not in the cart, add it
				return {
					...state,
					items: [...state.items, { ...item }],
				};
			}

		// return {
		//   ...state,
		//   items: [...state.items, action.payload],
		// };
		case "REMOVE_FROM_CART":
			const itemToRemove = action.payload;
			const updatedCartItemsAfterRemoval = state.items.filter(
				(cartItem) =>
					cartItem.slug !== itemToRemove.slug ||
					cartItem.variant.variant !== itemToRemove.variant.variant
			);

			return {
				...state,
				items: updatedCartItemsAfterRemoval,
			};

		//return {
		//   ...state,
		//   items: state.items.filter((item) => item.slug !== action.payload.slug),
		// };
		case "INCREMENT_ITEM_QUANTITY":
			const itemToIncrease = action.payload;
			const updatedCartItemsAfterIncrease = state.items.map((cartItem) =>
				cartItem.slug === itemToIncrease.slug &&
				cartItem.variant.variant === itemToIncrease.variant.variant
					? { ...cartItem, quantity: cartItem.quantity + 1 }
					: cartItem
			);

			return {
				...state,
				items: updatedCartItemsAfterIncrease,
			};

		case "DECREMENT_ITEM_QUANTITY":
			const itemToDecrease = action.payload;
			const updatedCartItemsAfterDecrease = state.items.map((cartItem) =>
				cartItem.slug === itemToDecrease.slug &&
				cartItem.variant.variant === itemToDecrease.variant.variant
					? { ...cartItem, quantity: Math.max(cartItem.quantity - 1, 1) }
					: cartItem
			);

			return {
				...state,
				items: updatedCartItemsAfterDecrease,
			};

		case "EMPTY_CART":
			// Clear the cart by setting items to an empty array
			return {
				...state,
				items: [],
			};

		case "UPDATE_ITEM_SHIPPING_COST":
			const { shippingItem, shippingCost } = action.payload;

			const savedItem = state.items.map((cartItem) =>
				cartItem.slug === shippingItem.slug &&
				cartItem.variant.variant === shippingItem.variant.variant
					? { ...cartItem, shipping_amount: shippingCost }
					: cartItem
			);

			return {
				...state,
				items: savedItem,
			};
		default:
			return state;
	}
};

export default cartReducer;
