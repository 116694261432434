import InputCom from "../Helpers/InputCom";
import PageTitle from "../Helpers/PageTitle";
import LayoutHomeTwo from "../Partials/LayoutHomeTwo";
import Thumbnail from "./Thumbnail";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import TrackingButtonCmp from "./TrackingButtonCmp";

export default function TrackingOrderDetail() {
	const { state } = useLocation();
	console.log(state);

	const order = state.order;
	console.log(order);

	const shopUserData = useSelector((state) => state.shopUserData.shopUserData);

	return (
		<LayoutHomeTwo childrenClasses="pt-0 pb-0">
			<div className="tracking-page-wrapper w-full">
				<div className="page-title mb-[40px]">
					<PageTitle title={`Order No:  ${order.order_number}`} />
				</div>
				<div className="content-wrapper w-full mb-[40px]">
					<div className="container-x mx-auto">
						<div className="checkout-main-content w-full">
							<div className="container-x mx-auto">
								<div className="w-full sm:mb-10 mb-5"></div>
								<div className="w-full lg:flex lg:space-x-[30px]">
									<div className="px-10 py-[30px] lg:w-1/2 w-full border border-[#EDEDED] bg-white">
										<div className="">
											<h2>Billing Details</h2>
											<div className="mt-2" style={{ fontSize: "0.8rem" }}>
												<div className="sm:flex sm:space-x-5 ">
													<div className="sm:w-1/2   sm:mb-0">Name</div>
													<div className="flex-1">{order.name}</div>
												</div>{" "}
												<div className="sm:flex sm:space-x-5 ">
													<div className="sm:w-1/2   sm:mb-0">Email</div>
													<div className="flex-1">{order.email}</div>
												</div>{" "}
												<div className="sm:flex sm:space-x-5 items-center ">
													<div className="sm:w-1/2   sm:mb-0">Address</div>
													<div className="flex-1">{order.billing_address}</div>
												</div>{" "}
												<div className="sm:flex sm:space-x-5 items-center ">
													<div className="sm:w-1/2  mb-5 sm:mb-0">Zip Code</div>
													<div className="flex-1">{order.billing_zip}</div>
												</div>
											</div>
										</div>
										<div className="mt-4">
											<h2>Shipping Details</h2>
											<div className="mt-2" style={{ fontSize: "0.8rem" }}>
												<div className="sm:flex sm:space-x-5 ">
													<div className="sm:w-1/2   sm:mb-0">Name</div>
													<div className="flex-1">{order.name}</div>
												</div>{" "}
												<div className="sm:flex sm:space-x-5 ">
													<div className="sm:w-1/2   sm:mb-0">Email</div>
													<div className="flex-1">{order.email}</div>
												</div>{" "}
												<div className="sm:flex sm:space-x-5 items-center ">
													<div className="sm:w-1/2   sm:mb-0">Address</div>
													<div className="flex-1">{order.billing_address}</div>
												</div>{" "}
												<div className="sm:flex sm:space-x-5 items-center ">
													<div className="sm:w-1/2  mb-5 sm:mb-0">Zip Code</div>
													<div className="flex-1">{order.billing_zip}</div>
												</div>
											</div>
										</div>
									</div>

									<div className="flex-1 ">
										<div className="w-full px-10 py-[30px] border border-[#EDEDED] bg-white">
											Order Summary
											<div className="mt-[30px]">
												<div className=" flex justify-between mb-5">
													<p className="text-[13px] font-medium text-qblack uppercase">
														SUBTOTAL
													</p>
													<p className="text-[15px] font-medium text-qblack uppercase">
														${order.total_amount}
													</p>
												</div>
											</div>
											<div className="w-full mt-[30px]">
												<div className="sub-total mb-6">
													<div className=" flex justify-between mb-5">
														<div>
															<span className="text-xs text-qgraytwo mb-3 block">
																SHIPPING
															</span>
															{/* <p className="text-base font-medium text-qblack">
                            Free Shipping
                          </p> */}
														</div>
														<p className="text-[15px] font-medium text-qblack">
															+${order.shipping_amount_total}
														</p>
													</div>
													<div className="w-full h-[1px] bg-[#EDEDED]"></div>
												</div>
											</div>
											<div className="mt-[30px]">
												<div className=" flex justify-between mb-5">
													<p className="text-2xl font-medium text-qblack">
														Total
													</p>
													<p className="text-2xl font-medium text-qred">
														$
														{Number(
															+order.total_amount + +order.shipping_amount_total
														).toFixed(2)}
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="mt-8 px-8 border border-[#EDEDED] bg-white">
							<h2 className="my-4">Order Details</h2>
							<table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
								<tbody>
									{/* table heading */}
									<tr className="text-[13px] font-medium text-black bg-[#F6F6F6] whitespace-nowrap px-2 border-b default-border-bottom uppercase">
										<td className="py-4 pl-10 block whitespace-nowrap min-w-[300px]">
											product
										</td>
										{/* <td className="py-4 whitespace-nowrap text-center">color</td> */}
										{/* <td className="py-4 whitespace-nowrap text-center">size</td> */}
										<td className="py-4 whitespace-nowrap text-center">
											price
										</td>
										<td className="py-4 whitespace-nowrap  text-center">
											quantity
										</td>
										<td className="py-4 whitespace-nowrap  text-center">
											total
										</td>
										<td className="py-4 whitespace-nowrap text-right w-[114px]"></td>
									</tr>
									{/* table heading end */}
									{order.order_details.map((item) => (
										<>
											<tr
												key={item.slug + item.color + item.size}
												className="bg-white  "
											>
												<td className="pl-10  py-4  ">
													<div className="flex space-x-6 items-center">
														<div className="w-[80px] h-[80px] overflow-hidden flex justify-center items-center border border-[#EDEDED]">
															<img
																src={item.image}
																alt="product"
																className="w-full h-full object-contain"
															/>
														</div>
														<div className="flex-1 flex flex-col">
															<p className="font-medium text-[15px] text-qblack">
																<Link
																	to={{ pathname: `/product/${item.slug}` }}
																	state={{ product: item }}
																>
																	{item.title}
																</Link>
															</p>
															<div style={{ fontSize: "0.7rem" }}>
																{item.color} - {item.size}
															</div>
														</div>
													</div>
												</td>

												<td className="text-center py-4 px-2">
													<div className="flex space-x-1 items-center justify-center">
														<span className="text-[15px] font-normal">
															{item.price}
														</span>
													</div>
												</td>
												<td className=" py-4 text-center">
													<div className="flex justify-center">
														<div className="flex justify-around items-center">
															{Number(item.quantity).toFixed(0)}
														</div>
													</div>
												</td>
												<td className="text-right py-4">
													<div className="flex space-x-1 items-center justify-center">
														<span className="text-[15px] font-normal">
															{item.quantity * item.price}
														</span>
													</div>
												</td>
												<td className="text-right py-4">
													<div className="flex space-x-1 items-center justify-center"></div>
												</td>
											</tr>
											<TrackingButtonCmp trackingId={item.trackingId} />
										</>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</LayoutHomeTwo>
	);
}
